<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-06-28 18:40:35
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\New_Page\test.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <ul>
      <li>5555</li>
      <li>5555</li>
      <li>5555</li>
      <li>5555</li>
      <li>5555</li>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style>
li{
  font-size: 40px;
}
</style>